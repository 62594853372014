<template>
  <!--begin::Signin-->
  <div class="login-form login-signin">
    <form class="form" novalidate="novalidate" id="kt_login_signin_form">
      <div class="pb-13 pt-lg-0 pt-5">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          Σύνδεση
        </h3>
        <span class="text-muted font-weight-bold font-size-h4"
          >Δεν έχεις λογαριασμό;
          <router-link
            class="text-primary font-weight-bolder"
            :to="{ name: 'register' }"
          >
            Δημιουργία
          </router-link>
        </span>
      </div>
      <div class="form-group">
        <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
        <div id="example-input-group-1" label="" label-for="example-input-1">
          <input
            class="form-control h-auto py-7 px-6 rounded-lg border-0"
            type="text"
            name="email"
            ref="email"
            v-model="form.email"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between mt-n5">
          <label class="font-size-h6 font-weight-bolder text-dark pt-5"
            >Password</label
          >
          <a
            class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
            id="kt_login_forgot"
            >Ξέχασες τον κωδικό;</a
          >
        </div>
        <div id="example-input-group-2" label="" label-for="example-input-2">
          <input
            class="form-control h-auto py-7 px-6 rounded-lg border-0"
            type="password"
            name="password"
            ref="password"
            v-model="form.password"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="pb-lg-0 pb-5">
        <button
          ref="kt_login_signin_submit"
          class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
        >
          Sign In
        </button>
      </div>
    </form>
  </div>
  <!--end::Signin-->
</template>

<!-- Load login custom page styles -->
<style lang="scss"></style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login",
  data() {
    return {
      form: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Το email είναι απαραίτητο"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Ο κωδικός είναι απαραίτητος"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // clear any previous logins
      this.$store
        .dispatch(LOGOUT)
        .catch(() => {})
        .finally(() => {
          // after clear execute login
          this.$store
            .dispatch(LOGIN, { email, password })
            .then(() => {
              this.$router.push({ name: "dashboard" });
            })
            .catch(({ data }) => {
              //TODO show login backend errors
              let message =
                "message" in data ? data.message : "Λάθος email ή κωδικός";

              Swal.fire({
                title: "",
                text: message,
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false
              });
            })
            .finally(() => {
              // Remove spinners after login request is finished
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
            });
        });
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Παρακαλώ, εισάγετε το email και τον κωδικό σας.",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });
  }
};
</script>
